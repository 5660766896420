*
{
    margin: 0;
    padding: 0;
}

/* html,
*/
/* body
{
    overflow: hidden;
}  */

html{
    background : #1e1a20;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}


.section
{
    display: flex;
    align-items: center;
    height: 100vh;
    position: relative;
    /* font-family: 'Cabin', sans-serif; */
    color: #ffeded;
    /* text-transform: uppercase; */
    /* font-size: 7vmin; */
    padding-left: 10%;
    padding-right: 10%;
}

section:nth-child(odd)
{
    justify-content: flex-end;
}


#mainNav {
    min-height: 3.5rem;
    background-color: #fff;
  }
  #mainNav .navbar-toggler {
    font-size: 80%;
    padding: 0.75rem;
    color: #64a19d;
    border: 1px solid #64a19d;
  }
  #mainNav .navbar-toggler:focus {
    outline: none;
  }
  #mainNav .navbar-brand {
    color: #000;
    font-weight: 700;
    padding: 0.9rem 0;
  }
  #mainNav .navbar-nav .nav-item:hover {
    color: fade(#fff, 80%);
    outline: none;
    background-color: transparent;
  }
  #mainNav .navbar-nav .nav-item:active, #mainNav .navbar-nav .nav-item:focus {
    outline: none;
    background-color: transparent;
  }
  @media (min-width: 992px) {
    #mainNav {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: none;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;
    }
    #mainNav .navbar-brand {
      padding: 0.5rem 0;
      color: rgba(255, 255, 255, 0.5);
    }
    #mainNav .nav-link {
      transition: none;
      padding: 2rem 1.5rem;
      color: rgba(255, 255, 255, 0.5);
    }
    #mainNav .nav-link:hover {
      color: rgba(255, 255, 255, 0.75);
    }
    #mainNav .nav-link:active {
      color: #fff;
    }
    #mainNav.navbar-shrink {
      background-color: #fff;
    }
    #mainNav.navbar-shrink .navbar-brand {
      color: #000;
    }
    #mainNav.navbar-shrink .nav-link {
      color: #000;
      padding: 1.5rem 1.5rem 1.25rem;
      border-bottom: 0.25rem solid transparent;
    }
    #mainNav.navbar-shrink .nav-link:hover {
      color: #64a19d;
    }
    #mainNav.navbar-shrink .nav-link:active {
      color: #467370;
    }
    #mainNav.navbar-shrink .nav-link.active {
      color: #64a19d;
      outline: none;
      border-bottom: 0.25rem solid #64a19d;
    }
  }

  
a:link { text-decoration: none; }


a:visited { text-decoration: none; }


a:hover { text-decoration: none; }


a:active { text-decoration: none; }
a { color: inherit; } 

p {
  color: #fff;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}